import axios from "axios";
import React, { useEffect, useState } from "react";
import "toastr/build/toastr.min.css";
import toastr from "toastr";
import { vAPIURL, vGlobalDateNow } from "../../Global";
import { Link } from "react-router-dom";
import format from 'date-fns/format';

const ViewPoint= () => {
	const [Loading, setLoading] = useState(false)
	const [MainPageData, setMainPageData] = useState([]);
	const date = new Date( vGlobalDateNow );

	const NOPData = MainPageData.map(Value => Value.NOP)
	const CaddyData = MainPageData.map(Value => Value.Caddy)
	const BuggyData = MainPageData.map(Value => Value.Buggy)
	const ReservationData = MainPageData.map(Value => Value.Reservation)
	const FnBServiceData = MainPageData.map(Value => Value.FnBService)
	const FnBProductionData = MainPageData.map(Value => Value.FnBProduction)
	const TotalBall = MainPageData.map(Value => Value.TotalBall)
	const MemberPlayer = MainPageData.map(Value => Value.MemberPlayer)
	const GuestPlayer = MainPageData.map(Value => Value.GuestPlayer)
	const FrequentPlayer = MainPageData.map(Value => Value.FrequentPlayer)
	const NewPlayer = MainPageData.map(Value => Value.NewPlayer)
	const VIPRoom = MainPageData.map(Value => Value.VIP)
	const Ballroom = MainPageData.map(Value => Value.Ballroom)
	const FlightNOP = MainPageData.map(Value => Value.FlightNOP)
	const FlightReservation = MainPageData.map(Value => Value.FlightReservation)
	const ReservationRevenue = MainPageData.map(Value => Value.ReservationRevenue)
	const [MainRevenueReservation, DecimalRevenueReservation] = String(ReservationRevenue).split(",");
	const RoomRate = MainPageData.map(Value => Value.RoomRate)
	const [MainRevenueRoom, DecimalRevenueRoom] = String(RoomRate).split(",")
	const RevenueGolf = MainPageData.map(Value => Value.RevenueGolf)
	const [MainRevenueGolf, DecimalRevenueGolf] = String(RevenueGolf).split(",")
	const RevenueResto = MainPageData.map(Value => Value.RevenueResto)
	const [MainRevenueResto, DecimalRevenueResto] = String(RevenueResto).split(",")
	const DrivingBallRevenue = MainPageData.map(Value => Value.DrivingBallRevenue)
	const [MainRevenueDriving, DecimalRevenueDriving] = String(DrivingBallRevenue).split(",")
	const VipBay = MainPageData.map(Value => Value.VipBay)

	const GetMainPageData = async() => {
		setLoading( true );
		let vStatus = 1;
		let vMessage = [];

		try{
			const vURL = vAPIURL + "/Golf/Main/DefaultPage/Dev";
			const vData = {
				Action: {
					Value: "Search"
				},
				Input: {
					Date: {
						Value: format( date, 'dd-MMM-yyyy' ),
					},
				},
			};

			const vConfig = {
				headers: {
					"Content-Type": "application/json",
					Authentication: `${localStorage.getItem("Authentication")}`,
				},
			};

			await axios
				.post( vURL, vData, vConfig )
				.then(
					( vResponse ) => {
						vStatus = vResponse.data.Status;

						for( var i = 0; i < vResponse.data.Message.length; i++ ){
							vMessage.push( vResponse.data.Message[ i ] );
						}

						if( vStatus === 1 ){
							setMainPageData( vResponse.data.Output.Result )
						}
					}
				)
				.catch(
					( vError ) => {
						vStatus = 2;
						vMessage.push( "Error" );
					}
				);
		}catch( vError ){
			vStatus = 2;
			vMessage.push( "Error" );
		}

		if( vMessage.length > 0 ){
			if( vStatus === 1 ){
				//toastr.success( vMessage.join( "</br>" ) );
			}
			else{
				toastr.error( vMessage.join( "</br>" ) );
			}
		}
		setLoading( false );
	}

	useEffect(() => {
		GetMainPageData()
	},[])

	return (
		<div className="col-12">
			{
				Loading ?
					<div className="row">
						<div className="col-12">
							<div className="d-flex justify-content-center">
								<i className="fas fa-3x fa-sync-alt fa-spin"/>
							</div>
						</div>
					</div>
					:
					<div className="row row-cols-2 row-cols-lg-5">
						<div className="col">
							<div className="small-box" id="Reservation-Box">
								<div className="inner">
									TOTAL RESERVATION
									<div className="inner-box">
										<div className="row row-cols-2">
											<div className="col-2">
											</div>
											<div className="col-3 Content">
												{ReservationData}
											</div>
											<div className="col-7 pl-0 Context">
												PAX
											</div>
										</div>
										<div className="row row-cols-2">
											<div className="col-2">
											</div>
											<div className="col-3 Content">
												{FlightReservation}
											</div>
											<div className="col-7 pl-0 Context">
												FLIGHT
											</div>
										</div>
									</div>
								</div>
								<div className="inner-footer">
									{/* Rp. {MainRevenueReservation} 
									<div className="small-decimal">
										, {DecimalRevenueReservation}
									</div> */}
								</div>
								<Link 
									className="small-box-footer"
									style={{ cursor:'pointer' }}
									to={`/Reservation`}
									onClick={(e) => window.scrollTo(0, 0)}
								>
									<a style={{ color:'black' }}>
										MORE INFO
									</a>
								</Link>
							</div>
						</div>
						<div className="col">
							<div className="small-box" id="MemberVsGuest-Box">
								<div className="inner">
									MEMBER VS GUEST
									<div className="inner-box">
										<div className="row row-cols-2">
											<div className="col-2">
											</div>
											<div className="col-2 p-0">
												M :
											</div>
											<div className="col-3 Content">
												{MemberPlayer}
											</div>
											<div className="col-5 pl-0 Context">
												PAX
											</div>
										</div>
										<div className="row row-cols-2">
											<div className="col-2">
											</div>
											<div className="col-2 p-0">
												G :
											</div>
											<div className="col-3 Content">
												{GuestPlayer}
											</div>
											<div className="col-5 pl-0 Context">
												PAX
											</div>
										</div>
									</div>
								</div>
								<div className="inner-footer">
								</div>
								<Link 
									className="small-box-footer"
									style={{ cursor:'pointer' }}
									to={`/NumberOfPlayer`}
									onClick={(e) => window.scrollTo(0, 0)}
								>
									<a style={{ color:'black' }}>
										MORE INFO
									</a>
								</Link>
							</div>
						</div>
						<div className="col">
							<div className="small-box" id="Buggy-Box">
								<div className="inner">
									TOTAL BUGGY ACTIVE
									<div className="inner-box">
										<div className="row row-cols-2">
											<div className="col-2">
											</div>
											<div className="col-3 Content">
												{BuggyData}
											</div>
											<div className="col-7 pl-0 Context">
												BUGGY
											</div>
										</div>
									</div>
								</div>
								<div className="inner-footer">
								</div>
								<Link 
									className="small-box-footer"
									style={{ cursor:'pointer' }}
									to={`/GolfOperation`}
									onClick={(e) => window.scrollTo(0, 0)}
								>
									<a style={{ color:'black' }}>
										MORE INFO
									</a>
								</Link>
							</div>
						</div>
						<div className="col">
							<div className="small-box" id="FnBService-Box">
								<div className="inner">
									TOTAL F&B SERVICE SELL OUT
									<div className="inner-box">
										<div className="row row-cols-2">
											<div className="col-2">
											</div>
											<div className="col-4 Content">
												{FnBServiceData}
											</div>
											<div className="col-6 pl-0 Context">
												PORTIONS
											</div>
										</div>
									</div>
								</div>
								<div className="inner-footer">
									{/* Rp. {MainRevenueResto} 
									<div className="small-decimal">
										, {DecimalRevenueResto}
									</div> */}
								</div>
								<Link 
									className="small-box-footer"
									style={{cursor:'pointer'}}
									to={`/F&BService`}
									onClick={(e) => window.scrollTo(0, 0)}
								>
									<a style={{ color:'black' }}>
										MORE INFO
									</a>
								</Link>
							</div>
						</div>
						<div className="col">
							<div className="small-box" id="Driving-Box">
								<div className="inner">
									TOTAL DRIVING RANGE BALL SELL OUT
									<div className="inner-box">
										<div className="row row-cols-2">
											<div className="col-2">
											</div>
											<div className="col-4 Content">
												{TotalBall}
											</div>
											<div className="col-6 pl-0 Context">
												BALL
											</div>
										</div>
										<div className="row row-cols-2">
											<div className="col-2">
											</div>
											<div className="col-4 Content">
												{VipBay}
											</div>
											<div className="col-6 pl-0 Context">
												VIP Bay
											</div>
										</div>
									</div>
								</div>
								<div className="inner-footer">
									{/* Rp. {MainRevenueDriving} 
									<div className="small-decimal">
										, {DecimalRevenueDriving}
									</div> */}
								</div>
								<Link 
									className="small-box-footer"
									style={{ cursor:'pointer' }}
									to={`/ExecutiveHighlight`}
									onClick={(e) => window.scrollTo(0, 0)}
								>
									<a style={{ color:'black' }}>
										MORE INFO
									</a>
								</Link>
							</div>
						</div>
						<div className="col">
							<div className="small-box" id="Nop-Box">
								<div className="inner">
									TOTAL NOP
									<div className="inner-box">
										<div className="row row-cols-2">
											<div className="col-2">
											</div>
											<div className="col-3 Content">
												{NOPData}
											</div>
											<div className="col-7 pl-0 Context">
												PAX
											</div>
										</div>
										<div className="row row-cols-2">
											<div className="col-2">
											</div>
											<div className="col-3 Content">
												{FlightNOP}
											</div>
											<div className="col-7 pl-0 Context">
												FLIGHT
											</div>
										</div>
									</div>
								</div>
								<div className="inner-footer">
									{/* Rp. {MainRevenueGolf} 
									<div className="small-decimal">
										, {DecimalRevenueGolf}
									</div> */}
								</div>
								<Link 
									className="small-box-footer"
									style={{cursor:'pointer'}}
									to={`/NumberOfPlayer`}
									onClick={(e) => window.scrollTo(0, 0)}
								>
									<a style={{ color:'black' }}>
										MORE INFO
									</a>
								</Link>
							</div>
						</div>
						<div className="col">
							<div className="small-box" id="NewVsFrequent-Box">
								<div className="inner">
									NEW PLAYER VS FREQUENT PLAYER
									<div className="inner-box">
										<div className="row row-cols-2">
											<div className="col-2">
											</div>
											<div className="col-2 p-0">
												N :
											</div>
											<div className="col-3 Content">
												{NewPlayer}
											</div>
											<div className="col-5 pl-0 Context">
												PAX
											</div>
										</div>
										<div className="row row-cols-2">
											<div className="col-2">
											</div>
											<div className="col-2 p-0">
												F :
											</div>
											<div className="col-3 Content">
												{FrequentPlayer}
											</div>
											<div className="col-5 pl-0 Context">
												PAX
											</div>
										</div>
									</div>
								</div>
								<div className="inner-footer">
								</div>
								<Link 
									className="small-box-footer"
									style={{ cursor:'pointer' }}
									to={`/NumberOfPlayer`}
									onClick={(e) => window.scrollTo(0, 0)}
								>
									<a style={{ color:'black' }}>
										MORE INFO
									</a>
								</Link>
							</div>
						</div>
						<div className="col">
							<div className="small-box" id="Caddy-Box">
								<div className="inner">
									TOTAL CADDY ATTENDANCE
									<div className="inner-box">
										<div className="row row-cols-2">
											<div className="col-2">
											</div>
											<div className="col-3 Content">
												{CaddyData}
											</div>
											<div className="col-7 pl-0 Context">
												CADDY
											</div>
										</div>
									</div>
								</div>
								<div className="inner-footer">
								</div>
								<Link 
									className="small-box-footer"
									style={{ cursor:'pointer' }}
									to={`/GolfOperation`}
									onClick={(e) => window.scrollTo(0, 0)}
								>
									<a style={{ color:'black' }}>
										MORE INFO
									</a>
								</Link>
							</div>
						</div>
						<div className="col">
							<div className="small-box" id="FnBProduction-Box">
								<div className="inner">
									TOTAL F&B PRODUCTION
									<div className="inner-box">
										<div className="row row-cols-2">
											<div className="col-2">
											</div>
											<div className="col-4 Content">
												{FnBProductionData}
											</div>
											<div className="col-6 pl-0 Context">
											PORTIONS
											</div>
										</div>
									</div>
								</div>
								<div className="inner-footer">
								</div>
								<Link 
									className="small-box-footer"
									style={{ cursor:'pointer' }}
									to={`/F&BProduction`}
									onClick={(e) => window.scrollTo(0, 0)}
								>
									<a style={{ color:'black' }}>
										MORE INFO
									</a>
								</Link>
							</div>
						</div>
						<div className="col">
							<div className="small-box" id="RoomRate-Box">
								<div className="inner">
									ROOM OCCUPATION
									<div className="inner-box">
										<div className="row row-cols-2" style={{ height: '100%' }}>
											<div className="col-2">
											</div>
											<div className="col-5">
												VIP
											</div>
											<div className="col-5">
												: {VIPRoom}
											</div>
											<div className="col-2">
											</div>
											<div className="col-5">
												Ballroom
											</div>
											<div className="col-5">
												: {Ballroom}
											</div>
										</div>
									</div>
								</div>
								<div className="inner-footer">
									{/* Rp. {MainRevenueRoom} 
									<div className="small-decimal">
										, {DecimalRevenueRoom}
									</div> */}
								</div>
								<Link 
									className="small-box-footer"
									style={{cursor:'pointer'}}
									to={`/ExecutiveHighlight`}
									onClick={(e) => window.scrollTo(0, 0)}
								>
									<a style={{ color:'black' }}>
										MORE INFO
									</a>
								</Link>
							</div>
						</div>
					</div>
			}
		</div>
	)
}

export default ViewPoint